body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pre-break {
  white-space: pre-line;
  word-wrap: break-word;
}

.pointer {
  cursor: pointer;
}

.can-load {
  transition: .4s;

  &.in {
    opacity: .4;
    pointer-events: none;
    user-select: none;
  }
}

.page-title {
  margin-bottom: 2rem !important;
  margin-top: 1rem !important;
  font-weight: 200 !important;
  text-align: center !important;
}

.maintenance-div {
  display: flex; 
  align-items: center; 
  gap : 1em; 
  justify-content: center;
}

a {
  cursor: pointer;
}

textarea[data-monospace] {
  font-family: monospace !important;
}
