path.onfocus {
    stroke : rgb(88, 24, 88);
    stroke-width: 3px;
}

div.tooltip {
    position: absolute;			
    text-align: center;			
    width: auto;					
    height: auto;					
    padding: 2px;				
    font: 12px sans-serif;		
    background: rgb(236, 244, 255);
    border: 0px;		
    border-radius: 8px;			
    pointer-events: none;			
}